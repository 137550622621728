<template>
  <div class="w-100">
    <div class="my-1 modal-branch">
      <button class="btn-select-branch my-2" @click.prevent="show($event)">
        <b-row
          ><b-col cols="8" class="p-0">Branch</b-col>
          <b-col cols="4" class="p-0"
            ><font-awesome-icon
              icon="chevron-right"
              class="text-right" /></b-col
        ></b-row>
      </button>
      <span class="branch-select"> ({{ selected.length }} Selected)</span>
    </div>
    <div>
      <b-modal
        v-model="showModal"
        centered
        @hide="hide"
        v-if="list && list.length > 0"
      >
        <template #modal-header>Branch List</template>
        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-model="selectAllBranch"
              @change="toggleAllBranch()"
              class="my-2"
            >
              All Branches
            </b-form-checkbox>
          </template>
          <b-tabs content-class="mt-3" v-if="!isCounting" ref="b_tabs">
            <b-tab v-for="(item, index) in list" :key="index">
              <template v-slot:title>
                <div style="width: 100px">
                  <div class="">
                    <span class="">{{ item.branch_group }}</span>
                    <span class=""> ({{ item.selected_count }}) </span>
                  </div>
                </div>
              </template>
              <b-form-group>
                <template>
                  <b-form-checkbox
                    :ref="`all_${item.branch_group}`"
                    v-model="allSelected"
                    :key="item.branch_group"
                    :value="item.branch_group"
                    @input="toggleAll(item.branch_group, index)"
                    :tabindex="index"
                  >
                    All
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group v-model="selected" class="mt-3">
                  <b-row>
                    <b-col cols="6" v-for="(data, i) in item.branch" :key="i">
                      <b-form-checkbox
                        class="mb-2"
                        :value="data.id"
                        :key="data.id"
                        @change="onChange(data.id)"
                      >
                        {{ data.branch_code }} {{ data.name }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-form-group>
        <template #modal-footer>
          <b-row>
            <b-col class="text-left">
              <b-button @click.prevent="hide" class="border-btn"
                >Cancel</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button class="submit-btn" @click.prevent="acceptSelectBranch"
                >Add {{ selected.length }} Branch</b-button
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array,
    },
    field: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
      selected: [],
      allSelected: [],
      selectAllBranch: false,
      allBranch: [],
      branch: "",
      allBranchCheck: 0,
      allSelectBranchCheck: 0,
      branchOld: [],
      lockSelected: [],
      isCounting: false,
      branchDelete: [],
      deleteBranch: [],
      tabIndex: 0,
      tabSelected: 0,
      selectedBranchType: [1],
      optionsBranch: [
        { text: "All Branch", value: [1] },
        { text: "Select Branch", value: null },
      ],
      countBranch: 0,
    };
  },
  created() {
    if (this.id === "0") {
      console.log("first");
      for (const participating1 of this.list) {
        this.allSelected.push(participating1.branch_group);
        for (const branch1 of participating1.branch) {
          this.selected.push(branch1.id);
          this.countBranch = this.selected.length;
        }
      }
    } else {
      if (this.field.branch_group_id) {
        if (this.field.branch_group_id == 1) {
          for (const participating2 of this.list) {
            this.allSelected.push(participating2.branch_group);
            for (const branch2 of participating2.branch) {
              this.selected.push(branch2.id);
              this.countBranch = this.selected.length;
            }
          }
        } else {
          for (const participating3 of this.list) {
            for (const branch3 of participating3.branch) {
              let branchSelectedCount = 0;
              if (branch3.is_check) {
                this.selected.push(branch3.id);
                this.lockSelected.push(branch3.id);
                branchSelectedCount = branchSelectedCount + 1;
                this.countBranch = this.selected.length;
              }
            }
          }
        }
      } else {
        for (const participating4 of this.list) {
          this.allSelected.push(participating4.branch_group);
          for (const branch4 of participating4.branch) {
            if (branch4.is_check) {
              this.selected.push(branch4.id);
            }
          }
        }
        this.countBranch = this.selected.length;
      }
    }
    this.isSelectAll();
    this.acceptSelectBranch();
  },
  methods: {
    show(evt) {
      if (evt.pointerType == "mouse") {
        this.showModal = true;
      } else {
        this.showModal = false;
      }
    },
    hide() {
      this.showModal = false;
    },
    toggleAll(branchGroup, tabIndex) {
      const focusGroup = this.list.filter((el) => {
        return el.branch_group == branchGroup;
      })[0];
      const checkBoxEl = this.$refs[`all_${branchGroup}`][0];
      const b_tabs = this.$refs.b_tabs;
      if (checkBoxEl) {
        if (checkBoxEl.isChecked == true) {
          for (const branchToAdd of focusGroup.branch) {
            if (!this.selected.includes(branchToAdd.id)) {
              this.selected.push(branchToAdd.id);
            }
            if (b_tabs && b_tabs.currentTab == tabIndex) {
              if (
                branchToAdd.is_check &&
                this.deleteBranch.includes(branchToAdd.id)
              ) {
                this.deleteBranch.splice(
                  this.deleteBranch.indexOf(branchToAdd.id),
                  1
                );
              }
            }
          }
        } else if (b_tabs) {
          for (const branchToRm of focusGroup.branch) {
            if (this.selected.includes(branchToRm.id)) {
              if (b_tabs.currentTab == tabIndex) {
                this.selected.splice(this.selected.indexOf(branchToRm.id), 1);
              }
            }
          }
        }
      }
      this.isSelectAll();
    },
    toggleAllBranch() {
      this.isCounting = true;
      this.selected = this.selected.filter((id) =>
        this.lockSelected.includes(id)
      );
      this.allSelected = [];
      if (this.selectAllBranch) {
        for (const participating of this.list) {
          this.allSelected.push(participating.branch_group);
          for (const branch of participating.branch) {
            if (!this.selected.includes(branch.id)) {
              this.selected.push(branch.id);
            }
          }
        }
      }
      this.isSelectAll();
    },
    isSelectAll() {
      let isCheckAllBranchCount = 0;
      for (const participating of this.list) {
        let branchSelectedCount = 0;
        for (const branch of participating.branch) {
          if (!participating.selected_count) participating.selected_count = 0;
          if (this.selected.includes(branch.id)) {
            branchSelectedCount = branchSelectedCount + 1;
          }
        }
        participating.selected_count = branchSelectedCount;
        const countBranchEl = this.$refs[`span_${participating.branch_group}`];
        if (countBranchEl) {
          countBranchEl[0].innerHTML = participating.selected_count;
        }
        if (branchSelectedCount == participating.branch.length) {
          if (!this.allSelected.includes(participating.branch_group)) {
            this.allSelected.push(participating.branch_group);
          }
          isCheckAllBranchCount = isCheckAllBranchCount + 1;
        } else if (this.allSelected.includes(participating.branch_group)) {
          this.allSelected.splice(
            this.allSelected.indexOf(participating.branch_group),
            1
          );
        }
      }
      if (isCheckAllBranchCount == this.list.length && this.list.length !== 0) {
        this.selectAllBranch = true;
      } else this.selectAllBranch = false;
      this.isCounting = false;
    },
    onChange(id) {
      this.isCounting = true;
      for (const branchToDeleteId of this.branchDelete) {
        if (
          !this.selected.includes(branchToDeleteId) &&
          !this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.push(branchToDeleteId);
        } else if (
          this.selected.includes(branchToDeleteId) &&
          this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.splice(
            this.deleteBranch.indexOf(branchToDeleteId),
            1
          );
        }
      }
      this.isSelectAll();
    },
    acceptSelectBranch() {
      if (this.field.id) {
        this.$emit("selectBranch", this.selected, this.field.id);
      } else {
        this.$emit("selectBranch", this.selected, 0);
        // this.countBranch = this.selected.length;
      }
      // this.$emit("deleteBranch", this.deleteBranch);
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-link {
  padding: 5px 10px;
}
::v-deep .modal-header {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 18px;
}
.ft-weight {
  font-weight: 600;
}
::v-deep .nav-tabs {
  border-bottom: 0;
}
::v-deep .modal-dialog {
  @media (min-width: 576px) {
    max-width: 800px;
  }
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-add-branch {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.btn-cancel {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.pointer {
  cursor: pointer;
}
.btn-select-branch {
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 25px;
  width: 15%;
}
@media screen and (max-width: 820px) {
  .btn-select-branch {
    width: 40%;
  }
}
</style>
